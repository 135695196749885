.app {
  font-family: "Lora";
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.top {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 98; /* Postavite z-index da bi bio iznad ostalih elemenata na stranici */
}

.nav-item {
  border-radius: 20px 20px 20px 0;
  padding-left: 10px;
  padding-right: 10px;
}
.nav-item:hover {
  border-radius: 20px 20px 20px 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: lightslategray;
}

.nav-item:active,
.nav-link:focus {
  border-radius: 20px 20px 20px 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #06a89e;
}

.nav-link {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}
.strucnost {
  transition: height 0.1s;
  color: white;
  background-color: #06a89e;
  height: 25px;
}
.hide-strucnost {
  transition: height 0.1s;
  color: white;
  background-color: #06a89e;
  height: 0px;
}
.icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}
/* slajder */
.slider-container {
  display: block;
  /* max-height: 900px; */
  position: relative;
  margin-top: 120px;
}
.static-image {
  display: none;
}

.slide {
  position: relative;
  text-align: center;
  color: black;
}

.slide-text {
  position: absolute;
  top: 50%;
  left: 20%;
  right: -8%;
  transform: translate(-18%, -50%);
  text-align: center;
  color: black; /* Crni tekst */
}

.t1 {
  text-align: left;
}
.t2 {
  text-align: right !important;
}
.slide-text h2 {
  font-size: 3rem; /* Veći naslov */
  margin-bottom: 20px;
}
.zelen {
  color: #06a89e !important;
  font-weight: bold;
  font-size: 2.75rem;
}
.slide-text p {
  font-size: 1.2rem; /* Veći tekst */
}

/* info */

.infocont {
  background-color: #06a89e !important; /* Tamna pozadina */
  padding-top: 50px;
  position: relative;
}
.card {
  background-color: #06a89e !important; /* Tamna pozadina */
  color: white !important; /* Beli tekst */
  border: none !important; /* Bez okvira */
}

.card-body {
  text-align: left;
}

.card-title {
  font-size: 21.3px;
  font-weight: bold;
  color: white !important;
}

.card-text {
  font-size: 17px;
  color: white !important;
}

.card-icon {
  color: white !important;
  margin-right: 10px;
  vertical-align: middle;
}

.element {
  transition: transform 0.3s ease-in-out;
}
.element:hover {
  transform: translateY(-10px); /* Pomerite element prema gore za 10px */
}
/* USLUGE */

.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5; /* Providnost pozadine */
}

.transparent-green-background {
  color: white;
  background-color: rgba(6, 168, 158, 0.8);
}

.naslov {
  margin-top: 20px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-style: solid;
}
.p1 {
  font-size: 18px;
}
.service-card {
  color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.service-card h2 {
  font-size: 1.1rem; /* Veliki naslov */
}

.service-card p {
  font-size: 1rem; /* Veliki tekst */
}
.service-card i {
  font-size: 2rem;
}

.service-card ul {
  list-style-type: none;
  padding-left: 0;
}

.service-card ul li {
  margin-bottom: 5px;
}

/* Novosti */

.novosti-component {
  background-color: white;
}
.cont1 {
  background-color: #e5f6f5;
}
.novosti-content {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-style: solid;
}
.novosti-content h4 {
  font-size: 1rem;
}

.novosti-content button {
  margin-bottom: 5px;
  border: 0;
  background-color: white;
}
/* tim */
.doctor-slider-container {
  background-color: #e5f6f5;
  padding: 20px 0;
}

.doctor-card {
  display: flex;
  align-items: center;
}

.doctor-info {
  flex: 1;
  padding-right: 20px; /* Prilagodi razmak između teksta i slike */
}

.doctor-image-container {
  width: 150px; /* Prilagodi veličinu slike */
  margin-left: auto; /* Postavi sliku sa desne strane kartice */
}

.doctor-image {
  width: 100%;
  height: auto;
}
.card-text1 {
  color: black;
}
.card-title1 {
  color: #06a89e;
}
.tim {
  font-size: 20px;
  border-bottom: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: #06a89e;
}

/* O nama */
.image-container {
  position: relative;
}

.gallery-image {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 168, 158, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-text {
  color: white;
  font-size: 20px;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

/* Usluge slider */

.card-img-top {
  height: 300px;
  object-fit: cover;
}
.uslugeback {
  background-color: #fff;
}
.card-usl {
  margin: 10px;
  padding: 10px;
}
.lista {
  color: darkslategrey;
}

.card-img-top-container {
  position: relative;
  overflow: hidden;
}

.card-img-top-container::after {
  content: ""; /* Dodajte pseudoelement */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 168, 158, 0.5); /* Zelena boja sa 50% providnosti */
  opacity: 0; /* Početna providnost 0 */
  transition: opacity 0.3s ease; /* Efekat prelaza providnosti */
}

.card-img-top-container:hover::after {
  opacity: 0.5; /* Providnost postavite na željenu vrednost pri hoveru */
}
/* CENOVNIK */
.cenovnik-item {
  border-bottom: 1px dotted black;
  text-align: center;
}

.cenovnik-item .row > div {
  padding: 5px;
}

.cenovnik-header {
  text-align: right;
  padding: 10px;
  font-weight: bold;
}
.cenatitle {
  font-size: 18px;
}
.naziv {
  text-align: left;
  background-color: #cdeeec;
  font-size: 15px;
}
.cena {
  text-align: right;
}
.nazivusl {
  text-align: left;
}
.pregled {
  text-align: center;
}
.cenovnik-usluge {
  font-size: 13px;
}

.navbar-toggler {
  border: 0;
  margin-right: 5px;
}
.partner-slide {
  text-align: center;
}

.partner-slide img {
  height: 80px;
  max-width: auto;
  vertical-align: middle;
}

/* kontakt */
.row.equal-height {
  display: flex;
  width: 100%;
}

.col-md-4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bg-primary1 {
  background-color: #06a89e !important;
}

h3 {
  margin-bottom: 1rem;
}

.dani {
  font-size: 18px;
  margin-bottom: 8px;
}

.linija {
  border: 0;
  border-bottom: 1px dotted black;
  margin-bottom: 10px;
}

.form-control {
  background-color: #06a89e !important;
  border: 0 !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0 !important;
}

.form-control,
.btn {
  width: 100%;
}

.btn {
  font-size: 18px !important;
  color: #06a89e !important;
  border-radius: 0 !important;
}

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}
.tim1 {
  border: 0;
  border-bottom: 1px white solid;
}
.tim11 {
  border: 0;
  border-bottom: 1px solid #06a89e;
}
/* news leter */
.form-control1 {
  padding: 10px;
  background-color: #cdeeec !important;
  border: 0 !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0 !important;
}
.tim3 {
  border: 0;
  border-bottom: 1px solid #06a89e;
  font-size: 25px;
  text-align: center;
}
.news1 {
  font-size: 18px;
  text-align: center;
}
.btnnews {
  color: white !important;
  background-color: #06a89e !important;
  border: 0 !important;
}
.fotcont {
  border: 0;
  border-bottom: 1px solid white;
}

@media only screen and (max-width: 600px) {
  .slider-container {
    max-height: 150px;
    position: relative;
    margin-top: 118px;
  }

  .slide {
    position: relative;
    text-align: center;
    color: black;
  }
  .slide img {
    width: 100%;
    height: auto;
  }

  .slide-text {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-18%, -50%);
    text-align: center;
    color: black; /* Crni tekst */
  }
  .t1 {
    text-align: left;
  }
  .slide-text h2 {
    font-size: 1.3rem; /* Veći naslov */
    margin-bottom: 10px;
  }
  .zelen {
    color: #06a89e !important;
    font-weight: 100;
  }
  .slide-text p {
    font-size: 0.8rem; /* Veći tekst */
  }
}
@media only screen and (min-width: 1400px) {
  .service-card i {
    font-size: 3.2rem;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}

@media (min-width: 1200px) {
  /* .slider-container{
    max-height: 491px;
  } */
  .container {
    max-width: 1140px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}
@media (min-width: 1400px) {
  /* .slider-container{
      max-height: 920px;
    } */
}
@media (min-width: 1700px) {
  .container {
    max-width: 1720px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}
@media (min-width: 1900px) {
  .container {
    max-width: 1820px !important; /* Povećajte ovu vrednost prema potrebi */
  }
}

/* slajder */
.contbtn {
  border: 0;
  background-color: rgba(255, 255, 255, 0.8);
  color: #06a89e;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 767px) {
  .slider-container {
    display: none;
  }

  .static-image {
    display: block;
    margin-top: 120px;
    height: 220px;
    position: relative;
  }
  .slika-slajd {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .static-image .slide-text {
    position: absolute;
    top: 110px;
    /* left: 80px; */
  }
  .zelen1 {
    color: #06a89e;
    font-size: 22px !important;
  }
}
.ftxt {
  text-decoration: none;
  color: white;
}

/* Kolacici */
.cookies-policy {
  /* margin-top: 120px; */
  padding: 70px;
  font-family: Arial, sans-serif;
}

.cookies-policy h1,
.cookies-policy h2,
.cookies-policy h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.cookies-policy p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.table-responsive {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .table thead {
    display: none;
  }

  .table tr {
    display: block;
    margin-bottom: 10px;
  }

  .table td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    white-space: nowrap;
    font-weight: bold;
  }
}

/* politika privatnosti */

.privacy-policy {
  padding: 20px;
  background-color: white;
  color: #333;
  font-family: "Lora";
  line-height: 1.6;
  margin-top: 120px;
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #444;
}

.privacy-policy h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy p {
  margin-bottom: 10px;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy ul ul {
  list-style-type: circle;
}

.privacy-policy ul li {
  margin-bottom: 5px;
}

.icon-header {
  width: 40px;
  height: auto;
}

.btn-menu-mobile {
  border: none !important;
  font-size: 25px !important;
}

.btn-menu-mobile:focus,
.btn-menu-mobile:active {
  outline: none !important;
  box-shadow: none !important;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(6, 186, 158, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
}

/* Start popup */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 0px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 97%;
  max-height: 97%;
  box-sizing: border-box;
}

.popup-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-close {
  position: absolute;
  top: 3px;
  right: 3px;
  background: none;
}

/* end popup */
/* Blog Card*/
.card1 {
  background-color: white !important;
  border-radius: 20px !important;
}
.blog-card-back {
  background: linear-gradient(rgba(6, 168, 158, 0.8), rgba(6, 168, 158, 0.8)),
    url(../src/img/card-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.card-img-top1 {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  height: 400px;
  object-fit: cover;
}
.card-title1 {
  font-size: 21.3px;
  font-weight: bold;
  color: #06a89e !important;
}
.container-blog {
  margin-top: 150px !important;
  min-height: 100vh;
  margin-bottom: 80px;
}

.blog-text {
  color: #06a89e !important;
}
.blog-text1 {
  font-weight: 800;
}
@media (max-width: 768px) {
  .container-blog {
    margin-top: 150px !important;
    height: auto;
  }
  .card-img-top {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    height: 220px;
    object-fit: cover;
  }
}

.service-item {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.service-item .icon {
  font-size: 36px;
  margin-bottom: 20px;
  color: #06a89e !important;
  align-items: center;
}
.service-item h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
}

.service-item p {
  font-size: 14px;
  color: #777;
}

.stretched-link {
  position: relative;
  text-decoration: none;
  color: inherit;
  z-index: 1;
}

.stretched-link:hover h3 {
  color: #06a89e;
}
.landing-back {
  background-color: #e5f6f5;
  margin-top: 120px !important;
}
.video-btn {
  color: white !important;
  background-color: #06a89e !important;
}
.video-back {
  background-color: #06a89e;
}
